import React, { useState } from "react"
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
} from "@/client/components/atoms/ui/card"
import Text from "@/client/components/atoms/text"
import { CURRENCY_SYMBOL, CURRENCY_DISPLAY_TEXT } from "@/client/constants"
import { UserCountryType } from "@/client/lib/interface"
import { Check } from "lucide-react"
import { AIIcon } from "@/client/components/assets"
import { Tabs, TabsContent } from "@/client/components/atoms/ui/tabs"
import TabGroup from "@/client/components/atoms/tab-group"
import {
	DiscountDetailsBO,
	DiscountType,
	PlanBO,
} from "@/server/features/user/bo"
import { cn } from "@/client/lib/utils"
import { InfoIcon } from "../../atoms/SVGS"

type PricingCardTemplateProp = {
	planType?: "FREE" | "PRO_UNPAID" | "PRO_PAID"
	countryCode: UserCountryType
	buttonText: string
	isDisabled?: boolean
	onClick: (plan_name: string) => void
	title: string
	description: string[]
	plans: (PlanBO | null)[]
	planTitle?: React.ReactNode
	planState?: React.ReactNode
	loading?: boolean
	discountDetails?: DiscountDetailsBO
}

const PricingCardTemplateHeader = ({
	plans,
	currency,
	currencyInText,
	planTitle,
	planState,
	setSelectedTerm,
	discountDetails,
}: {
	plans: PlanBO[]
	currency: string
	currencyInText: string
	planTitle?: React.ReactNode
	planState?: React.ReactNode
	setSelectedTerm: React.Dispatch<React.SetStateAction<string | undefined>>
	discountDetails?: DiscountDetailsBO
}) => (
	<CardHeader className="lg:px-12 lg:py-5 px-6 py-3">
		<Tabs
			defaultValue={plans[0].plan_period.toLowerCase()}
			className={`flex flex-row justify-between`}
			onValueChange={setSelectedTerm}>
			{/* <div className="flex min-h-[100px]"> */}
			<>
				{plans?.map((plan: PlanBO | PlanBO, index) => (
					<PricingCardTemplatePricing
						key={`pricing-details-${index}`}
						planTitle={
							planTitle || (
								<Text variant="text-semibold">
									<AIIcon /> Pro
								</Text>
							)
						}
						current_plan_price={plan.current_plan_price}
						user_plan_price={plan.plan_price}
						term={plan.plan_period.toLowerCase()}
						currency={currency}
						currencyInText={currencyInText}
						discountDetails={discountDetails}
					/>
				))}
				{/* Annually | Monthly Buttons */}
				{plans.length > 1 ? (
					<TabGroup
						values={plans.map(plan => plan.plan_period.toLowerCase())}
						tabGroupKey="plans"
					/>
				) : planState ? (
					planState
				) : null}
			</>
			{/* </div> */}
		</Tabs>
	</CardHeader>
)

const PricingCardTemplatePricing = ({
	planTitle,
	current_plan_price,
	user_plan_price,
	term,
	currency,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	currencyInText,
	discountDetails,
}: {
	planTitle: React.ReactNode
	current_plan_price: number
	user_plan_price: number
	term: string
	currency: string
	currencyInText: string
	discountDetails?: DiscountDetailsBO
}) => {
	const isDiscountPlan = current_plan_price != user_plan_price
	const discountPlanHasHeader =
		discountDetails && discountDetails.discount_type === DiscountType.REFERRAL
	return (
		<TabsContent value={term} className="m-0 flex flex-col justify-between">
			<Text variant="text-semibold">{planTitle}</Text>
			<>
				<Text
					variant="text-thin"
					className="text-sm self-end items-start flex-col mt-2">
					{discountPlanHasHeader && (
						<div className="relative">
							<div className="flex justify-start pb-2">
								{discountDetails && (
									<span className="bg-white text-primary border border-primary py-1 px-2 rounded-[4px] text-xs">
										{`${discountDetails.discount_type} ${discountDetails.discount_percent}% OFF`}
									</span>
								)}
							</div>
						</div>
					)}
					{isDiscountPlan && (
						<div className="relative">
							<span className="text-thin text-base whitespace-nowrap">
								{currency}
								{user_plan_price}
							</span>{" "}
							<span className="text-thin text-xs">/{term.toLowerCase()} </span>
							{!discountPlanHasHeader && (
								<span className="absolute -top-2 h-5 w-5">
									<InfoIcon />
								</span>
							)}
						</div>
					)}

					<div className="relative">
						<span
							className={`whitespace-nowrap ${
								isDiscountPlan
									? "line-through text-sm text-thin"
									: "text-base text-thin"
							}`}>
							{currency}
							{current_plan_price}{" "}
						</span>
						<span
							className={`text-thin text-xs ${
								isDiscountPlan ? "line-through" : ""
							}`}>
							/{term.toLowerCase()}{" "}
						</span>
					</div>
				</Text>
			</>
		</TabsContent>
	)
}

const PricingCardTemplateAction = ({
	buttonText = "",
	isDisabled = false,
	onClick,
	children = null,
	asChild = false,
	loading = false,
}: {
	buttonText: string
	isDisabled?: boolean
	onClick: () => void
	asChild?: boolean
	children?: React.ReactElement | null
	loading?: boolean
}) => (
	<CardContent className="space-y-2 lg:px-12 lg:py-5 px-6 py-3">
		{asChild ? (
			children
		) : buttonText ? (
			<button
				className={cn(
					"w-full bg-primary text-white disabled:bg-zinc-200 disabled:text-zinc-400 px-10 py-5 rounded-lg font-semibold leading-6 text-xs whitespace-nowrap",
					isDisabled
						? "cursor-not-allowed"
						: loading
							? "animate-pulse cursor-wait"
							: "cursor-pointer"
				)}
				disabled={isDisabled}
				onClick={onClick}>
				{loading ? "Loading..." : buttonText}
			</button>
		) : null}
	</CardContent>
)

const PricingCardTemplateDescription = ({
	title = "",
	description = [],
	isDisabled = false,
}: {
	title: string
	description: string[]
	isDisabled?: boolean
}) => (
	<CardFooter className="flex-col items-start gap-y-5 text-sm lg:px-12 lg:py-5 px-6 py-3">
		<Text variant="text-semibold">{title}</Text>
		<div className="flex flex-col gap-y-2.5 leading-6">
			{description.map((text, index) => (
				<Text variant="text-light" key={`desc-${index}`}>
					<Check
						className={`h-3 w-3 ${!isDisabled ? "text-primary" : ""}`}
						strokeWidth={4}
					/>
					{text}
				</Text>
			))}
		</div>
	</CardFooter>
)

PricingCardTemplateHeader.displayName = "PricingCardTemplateHeader"
PricingCardTemplatePricing.displayName = "PricingCardTemplatePricing"
PricingCardTemplateAction.displayName = "PricingCardTemplateAction"
PricingCardTemplateDescription.displayName = "PricingCardTemplateDescription"

const PricingCardTemplate = ({
	buttonText = "",
	isDisabled = false,
	onClick,
	title,
	description,
	plans,
	countryCode,
	planTitle,
	planState,
	loading = false,
	discountDetails,
}: PricingCardTemplateProp) => {
	const [selectedTerm, setSelectedTerm] = useState<undefined | string>()

	const onSelect = () => {
		let plan_name
		if (selectedTerm) {
			plan_name = plans.filter(
				plan => plan?.plan_period == selectedTerm?.toUpperCase()
			)[0]?.plan_code
		} else {
			plan_name = plans[0]?.plan_code
		}
		if (plan_name) onClick(plan_name)
	}

	const purchasable_plans = plans.filter(plan => plan != null) as PlanBO[]

	return (
		<div className="w-full max-w-[480px] border-neutral-200 border rounded-md lg:first:rounded-r-none lg:last:rounded-l-none lg:last:border-r lg:border-r-0 first:rounded-b-none last:rounded-t-none last:border-b border-b-0">
			<Card className="py-5 border-none rounded shadow-none">
				<PricingCardTemplateHeader
					plans={purchasable_plans}
					currencyInText={CURRENCY_DISPLAY_TEXT[countryCode]}
					currency={CURRENCY_SYMBOL[countryCode]}
					planTitle={planTitle}
					planState={planState}
					setSelectedTerm={setSelectedTerm}
					discountDetails={discountDetails}
				/>
				<PricingCardTemplateAction
					buttonText={buttonText}
					isDisabled={isDisabled}
					onClick={onSelect}
					loading={loading}
				/>
				<PricingCardTemplateDescription
					title={title}
					description={description}
					isDisabled={isDisabled}
				/>
			</Card>
		</div>
	)
}

export default PricingCardTemplate
